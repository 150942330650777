import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './banner/banner.component';
import { ContactComponent } from './contact/contact.component';
import { MoreProyectsComponent } from './more-proyects/more-proyects.component';
import { NgbModule, NgbNav, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { ReactiveFormsModule } from '@angular/forms';  // Importa ReactiveFormsModule
export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    HomeComponent,
    BannerComponent,
    AboutComponent,
    MoreProyectsComponent,
    ContactComponent,
  ],
  imports: [
    RecaptchaV3Module,
    CommonModule,
    NgbNavModule,
    CarouselModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdnAV4qAAAAAPdJDf2lrZrf9CZQ43R4XrCq6zZx' }  // Pasa tu Site Key para reCAPTCHA v3
  ]
})
export class HomeModule { }
