import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;

  constructor(private fb: FormBuilder, private recaptchaV3Service: ReCaptchaV3Service, private http: HttpClient) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.contactForm.valid) {
      // Ejecutar el reCAPTCHA v3
      this.recaptchaV3Service.execute('contactFormSubmit')
        .subscribe(token => {
          const formData = {
            ...this.contactForm.value,
            recaptchaResponse: token  // Añadir el token reCAPTCHA v3
          };

          this.http.post('http://app.aii.cr/contact', formData).subscribe(response => {
            console.log('Message sent successfully!', response);
          }, error => {
            console.error('Error sending message', error);
          });
        });
    }
  }

}
