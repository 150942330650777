<section class="section" id="contact">
  <div class="container contact">
    <div class="section-box">
      <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
        <h4 class="e-font contact-title big-title">
          05. {{ "Contact.Title" | translate }}
        </h4>
      </div>
      <p data-aos="fade-up" data-aos-duration="1000">
        {{ "Contact.Content" | translate }}
      </p>
      <br>
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div>
          <label for="name">{{ "Contact.Name" | translate }}:</label>
          <input id="name" formControlName="name" type="text" required />
          <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="validation-message">
            {{ "Contact.ErrorName" | translate }}
          </div>
        </div>
        <div>
          <label for="name">{{ "Contact.Phone" | translate }}:</label>
          <input id="name" formControlName="phone" type="text" required />
        </div>
        <div>
          <label for="email">{{ "Contact.Email" | translate }}:</label>
          <input id="email" type="email" formControlName="email" required />
          <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched" class="validation-message">
            {{ "Contact.ErrorEmail" | translate }}
          </div>
        </div>
        <div>
          <label for="message">{{ "Contact.Message" | translate }}:</label>
          <textarea id="message" formControlName="message" required></textarea>
          <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched" class="validation-message">
            {{ "Contact.ErrorMessage" | translate }}
          </div>
        </div>
        <div class="m-3">
          <button
            class="main-btn"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {{ "Contact.Btn" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
