import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from "src/app/services/language/language.service"
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'aii';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translateService: TranslateService,
    private location: Location,
    private languageService: LanguageService
    ){
    }
  ngOnInit(): void{

    this.languageService.initLanguage()


    this.titleService.setTitle( "Artifical Intelligence Integrator" );

    this.metaService.addTags([
      {name: 'keywords', content: 'Artifial Intelligence, Costa Rica, AI, software, developer'},
      {name: 'description', content: 'Con experiencia en el desarrollo de soluciones de inteligencia artificial, interfaces conversacionales y automatización, nuestro equipo se enfoca en liderar, proponer y ejecutar ideas innovadoras. Nos especializamos en escribir y optimizar código limpio, escalable y reutilizable para maximizar la eficiencia y el impacto de las soluciones tecnológicas.'},
    ]);


    AOS.init();

  }
}
