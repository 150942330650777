<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <!--li>
                <a href="" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li-->
            <li>
                <a href="https://www.linkedin.com/in/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a href="mailto:info@aii.cr" target='_blank'>info&#64;aii.cr </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <div class="mt-2">2024&copy; | AII Costa Rica</div>
    </div>

</footer>
