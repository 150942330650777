<section class="section services" id="service">
    <div class="container">
        <div class="section-box">
            <div class="">
                <h3 class="section-title">
                    <span class='n-section-title'>03.</span> {{"Service.Title" | translate}}
                </h3>
            </div>
            <div class="mt-5">
                <div class="row p-0">

                    <div *ngFor='let service of "Service.Services" | translate; let i = index'
                      (click)="redirect(service['demoLink'], $event)"
                     class="col-12 col-md-6 col-lg-6 col-xl-4 proyect-col" data-aos="fade-up" data-aos-duration="1000">
                        <div class="service-box">
                            <div class="row w-100 text-left m-0 p-0">
                                <div class="mx-auto w-100" style="display: flex; justify-content: center;">
                                  <svg  xmlns="http://www.w3.org/2000/svg" *ngIf="service.Icon==='code'"  width="70"  height="70"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-code"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 8l-4 4l4 4" /><path d="M17 8l4 4l-4 4" /><path d="M14 4l-4 16" /></svg>
                                  <svg  xmlns="http://www.w3.org/2000/svg" *ngIf="service.Icon==='automation'" width="70"  height="70"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-automation"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M13 20.693c-.905 .628 -2.36 .292 -2.675 -1.01a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.492 .362 1.716 2.219 .674 3.03" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /><path d="M17 22l5 -3l-5 -3z" /></svg>
                                  <svg  xmlns="http://www.w3.org/2000/svg" *ngIf="service.Icon==='robot'"  width="70"  height="70"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-robot"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" /><path d="M12 2v2" /><path d="M9 12v9" /><path d="M15 12v9" /><path d="M5 16l4 -2" /><path d="M15 14l4 2" /><path d="M9 18h6" /><path d="M10 8v.01" /><path d="M14 8v.01" /></svg>
                                </div>
                                <h5 class="service-title mt-4">{{service.Title}}</h5>
                                <p class="service-description">
                                    {{service.Description}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- <div class="w-100 text-center mt-5 pt-5">
                <a [routerLink]="'/proyectos'" class='main-btn'>Ver todo</a>
            </div> -->
        </div>
    </div>
</section>
